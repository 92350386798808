<template>
  <div>
    <div
      v-if="!loading"
      style="
        height: 100vh;
        contain: content;
        background: var(--v-greyRaised-base) !important;
      "
    >
      <div
        style="
          height: 36px;
          width: 100%;
          display: flex;
          align-items: center;
          background-color: var(--v-secondary-base) !important;
          background: var(--v-secondary-base) !important;
        "
      >
        <v-btn plain icon class="mx-2" @click="$router.go(-1)"
          ><v-icon>arrow_back</v-icon></v-btn
        >
        <span style="font-size: 14px" class="ml-2"
          ><b> Manage Booking: 
            
            <v-tooltip bottom v-if="booking.movementType">
            <template v-slot:activator="{ on }">
                <v-chip  small
              class="primaryText--text"
              v-on="on"
              outlined
              style="border: none; font-size: 14px">
              <v-avatar size="16"  left :color="booking.movementType == 'EXPORT' ? 'deep-orange' : booking.movementType == 'IMPORT'? 'blue' :'green' ">
                <h4 style="color: white">
                  {{ booking.movementType.charAt(0) }}
                </h4>
              </v-avatar>
              {{ booking.orderNo }}
              
                </v-chip>
              
            </template>
            <span>{{ $Format.capitalizeFirstLetter(booking.movementType) }} Booking</span>
          </v-tooltip>
            </b></span
        >
       
        <v-menu
          offset-y
          rounded
          v-if="booking.state !== 'Cancelled' && booking.state !== 'Futile'"
        >
          <template v-slot:activator="{ on }">
            <v-chip  small v-on="on"
              class="primaryText--text"
              outlined
              style="border: none">
            <v-icon small left :color="bookingTypeColor(booking.type)">
                {{ bookingTypeIcon(booking.type) }}
            </v-icon>
            Type: {{ $Format.capitalizeFirstLetter(booking.type) }}
            <v-icon small right>arrow_drop_down</v-icon>

        </v-chip>
          </template>
          <v-list dense class="glass" subheader>
            <v-subheader style="font-size: 14px">Booking Type</v-subheader>
            <v-divider></v-divider>
            <v-list-item
              v-for="(type, index) in bookingTypes"
              :key="index"
              @click="(booking.type = type.value),saveBooking()"
              style="height: 30px"
            >
              <v-list-item-action class="mr-1 pr-1">
                <v-icon small :color="type.color">{{ type.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="ml-0 pl-0">
                <v-list-item-title style="font-size: 12px"> {{ $Format.capitalizeFirstLetter(type.value) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y rounded>
                    <template v-slot:activator="{ on }">
                      <v-chip  small v-on="on"
              class="primaryText--text"
              outlined
              style="border: none">
                        <v-icon small left color="amber">import_export</v-icon>
                        <span style="text-transform: none">
                          {{ booking.serviceType }}
                        </span>
                        <v-icon small right>arrow_drop_down</v-icon>
                      </v-chip>
                    </template>
                    <v-list dense subheader>
                      <v-list-item @click="booking.serviceType = 'FCL',saveBooking()" style="height: 30px">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">FCL</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >Full Container Load</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.serviceType = 'LCL',saveBooking()" style="height: 30px">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">LCL</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >Less than Container Load</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.serviceType = 'BREAKBULK',saveBooking()" style="height: 30px">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">Breakbulk</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
        <v-menu
          offset-y
          rounded
          v-if="booking.state !== 'Cancelled' && booking.state !== 'Futile'"
        >
          <template v-slot:activator="{ on }">
            <v-chip
              small
              class="primaryText--text"
              v-on="on"
              outlined
              style="border: none"
            >
              <v-icon
                left
                v-on="on"
                small
                :color="getMainStatusColor(booking.state)"
                >fiber_manual_record</v-icon
              >
              {{ booking.state }}
              <v-icon small right>arrow_drop_down</v-icon>
            </v-chip>
          </template>
          <v-list dense class="glass" subheader>
            <v-subheader style="font-size: 14px">Booking Status</v-subheader>
            <v-divider></v-divider>
            <v-list-item
              v-for="(status, index) in bookingStatus.filter(
                (x) => x.name !== 'Cancelled' || x.name !== 'Futile'
              )"
              :key="index"
              @click="(booking.state = status.name), updateManualStatus()"
              style="height: 30px"
            >
              <v-list-item-action class="mr-1 pr-1">
                <v-icon small :color="status.color">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content class="ml-0 pl-0">
                <v-list-item-title style="font-size: 12px">{{
                  status.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

       
        <v-menu offset-y rounded v-else>
          <template v-slot:activator="{ on }">
            <v-chip
              small
              class="primaryText--text ml-1"
              v-on="on"
              outlined
              style="border: none"
            >
              <v-icon
                left
                v-on="on"
                small
                :color="getMainStatusColor(booking.state)"
                >fiber_manual_record</v-icon
              >
              {{ booking.state }}
              <v-icon small right>arrow_drop_down</v-icon>
            </v-chip>
          </template>
          <v-list dense class="glass" subheader>
            <v-subheader style="font-size: 14px">Booking Status</v-subheader>
            <v-divider></v-divider>
            <v-list-item
              v-for="(status, index) in bookingStatus.filter(
                (x) => x.name === 'Cancelled' || x.name === 'Futile'
              )"
              :key="index"
              @click="(booking.state = status.name), updateManualStatus()"
              style="height: 30px"
            >
              <v-list-item-action class="mr-1 pr-1">
                <v-icon small :color="status.color">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content class="ml-0 pl-0">
                <v-list-item-title style="font-size: 12px">{{
                  status.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>
        <v-chip
          v-if="booking && booking.serviceType =='BREAKBULK'"
          outlined
          style="border: none"
          class="mx-1"
          >
          <v-icon small left color="orange">fiber_manual_record</v-icon>
          Breakbulk</v-chip
        >
        <v-tooltip bottom v-else-if="booking && booking.bookingContainers">
          <template v-slot:activator="{ on }">
            <v-chip v-on="on"
          outlined small
          style="border: none"
          class="mx-1"
          >
          <v-icon small left :color="booking.serviceType == 'FCL'?'teal':'lime'">fiber_manual_record</v-icon>
          {{booking.bookingContainers.length}} 
          <span class="ml-1">Containers</span>
          </v-chip
        >
</template>
<span>{{ booking.serviceType }}</span>
          </v-tooltip>
        <v-tooltip bottom v-if="booking.shippingLine">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none" small>
                <v-avatar
                  left
                  color="white"
                  size="32"
                  v-if="booking.shippingLine"
                >
                  <v-img
                    v-if="booking.shippingLine && booking.shippingLine.logo"
                    :src="booking.shippingLine.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-icon left v-else :color="'grey'">directions_boat</v-icon>
                <span v-if="booking.shippingLine"
                  ><span v-if="booking.shippingLine.friendlyName" style="font-size: 12px"
                    >{{ booking.shippingLine.friendlyName }}:
                  </span>
                  <span v-else style="font-size: 12px">{{ booking.shippingLine.name }}: </span>
                </span>
                <span v-if="booking.vessel" class="ml-1" style="font-size: 12px"
                  >{{ booking.vessel.name }}
                  {{ booking.mainCarriageConveyanceNumber }}</span
                >
                <span v-else>No Vessel</span>
              </v-chip>
            </template>
            <span>Vessel</span>
          </v-tooltip>
       
        <!-- <div class="ml-2" :key="routeKey"> -->
          <v-tooltip bottom v-for="(location, index) in fullRoute" :key="index">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-chip small outlined label class="primaryText--text" style="border: none">
                  <v-avatar size="24" left>
                    <v-img
                      contain
                      v-if="location.locode"
                      :src="`https://cdn.loglive.io/flags/4x3/${location.locode
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img> </v-avatar
                  >{{ location.locode }}
                </v-chip>
                <v-icon
                  class="mx-3"
                  color="grey"
                  x-small
                  v-if="index < fullRoute.length - 1"
                  >east</v-icon
                >
              </div>
            </template>
            <span style="font-size: 11px"
              >{{ location.type }}: {{ location.locode }}</span
            >
          </v-tooltip>
      </div>
      <div class="contents">
        <div class="mainContentWrap">
          <v-tabs height="30px" v-model="currentTab" class="tabby" style="">
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :href="`#${tab.value}`"
            >
              {{ tab.name }}
            </v-tab>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="booking.customer">
          <template v-slot:activator="{ on }">
            <v-chip
              small
              class="primaryText--text mt-1"
              v-on="on"
              outlined
              style="border: none"
            >
              <v-icon
                class="mr-2"
                small
                left
                v-if="booking.clientStatus"
                :color="getClientStatusColor(booking.clientStatus)"
                >fiber_manual_record</v-icon
              >
              <v-avatar
                size="20"
                :color="
                  booking.customer && booking.customer.logo ? 'white' : 'grey'
                "
                left
              >
                <v-img
                  v-if="booking.customer.logo"
                  :src="booking.customer.logo"
                  contain
                ></v-img>
                <h3 v-else style="color: white">
                  {{ booking.customer.name.charAt(0) }}
                </h3>
              </v-avatar>
              <span v-if="booking.customer.alias" style="font-size: 12px">{{
                booking.customer.alias
              }}</span>
              <span v-else style="font-size: 12px">{{ booking.customer.name }}</span>
            </v-chip>
          </template>
          <span
            >Customer
            <span v-if="booking.clientStatus">
              - Status:
              {{ booking.clientStatus }}</span
            ></span
          >
        </v-tooltip>
            <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none" small class="mt-1">
                <v-icon left :color="'primary'">outbound</v-icon>
                <span v-if="booking.shipper"
                  ><span v-if="booking.shipper.alias" style="font-size: 12px">{{
                    booking.shipper.alias
                  }}</span>
                  <span v-else style="font-size: 12px">{{ booking.shipper.name }}</span></span
                >
                <span v-else style="font-size: 12px">No Shipper</span>
              </v-chip>
            </template>
            <span>Shipper</span>
          </v-tooltip>
          <v-tooltip bottom v-if="booking.shipper && booking.onBehalfShipper">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none" small class="mt-1">
                <v-icon left :color="'primary'">outbound</v-icon>
                o.b.o
                <span v-if="booking.onBehalfShipper" class="ml-1"
                  ><span v-if="booking.onBehalfShipper.alias" style="font-size: 12px">{{
                    booking.onBehalfShipper.alias
                  }}</span>
                  <span v-else class="ml-1" style="font-size: 12px">{{
                    booking.onBehalfShipper.name
                  }}</span></span
                >
              </v-chip>
            </template>
            <span>On behalf of Shipper</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none" small class="mt-1">
                <v-icon left :color="'primary'">call_received</v-icon>
                <span v-if="booking.consignee">
                  <span v-if="booking.consignee.alias" style="font-size: 12px">{{
                    booking.consignee.alias
                  }}</span>
                  <span v-else style="font-size: 12px">{{ booking.consignee.name }}</span>
                </span>
                <span v-else style="font-size: 12px">No Consignee</span>
              </v-chip>
            </template>
            <span>Consignee</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none" small class="mt-1">
                <v-icon left small :color="booking.incoTerm ? 'primary' : 'grey'"
                  >swap_horiz</v-icon
                >
                <span v-if="booking.incoTerm" style="font-size: 12px">{{ booking.incoTerm }}</span>
                <span v-else style="font-size: 12px">No Inco Term</span>
              </v-chip>
            </template>
            <span>Inco Term</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none" small class="mt-1">
                <v-icon left small :color="'primary'">thermostat</v-icon>
                <span v-if="booking.regimeCode" style="font-size: 12px">{{ booking.regimeCode }}</span>
                <span v-else style="font-size: 12px">No Regime Code</span>
                <v-icon
                  v-if="booking.regime && booking.regime.steri"
                  color="blue" small
                  right
                  >ac_unit</v-icon
                >
              </v-chip>
            </template>
            <span
              >Regime Code
              <span v-if="booking.regime && booking.regime.steri" class="ml-1"
                >: STERI</span
              ></span
            >
          </v-tooltip>
          <v-chip outlined style="border: none" small class="mt-1">
            <v-icon left small :color="'primary'">category</v-icon>
            <span class="ml-1" v-if="productList().length > 0" style="font-size: 12px">
              {{ productList() }}</span
            >
            <span v-else style="font-size: 12px">No Products</span>
          </v-chip>
          <v-chip v-if="booking.isHazardous" outlined small class="mt-1" style="border: none;font-size: 12px">
            <v-icon color="orange" left>warning</v-icon> Hazardous
          </v-chip>
          </v-tabs>
          <div class="mainContent">
            <v-tabs-items
              v-model="currentTab"
              style="height: inherit; width: 100%"
            >
              <v-tab-item style="height: inherit; width: 100%" value="overview">
                <Overview :booking="booking" :linkedFiles="linkedFiles" :countries="countries" :organisations="organisations" :containerKey="containerKey" :productServices="productServices" 
                @refreshContainers="getContainers()" @removeSubscriber="removeSubscriber" @setSubscribers="setNewSubscribers" @bookingChange="saveBooking()" @bookingUpdate="bookingUpdate" />
              </v-tab-item>
              <v-tab-item style="height: inherit; width: 100%" value="content">
                <Content :key="containerKey" :containerTypes="containerTypes" :booking="booking" :countries="countries" :organisations="organisations" :containerKey="containerKey" :productServices="productServices" @bookingChange="saveBooking()" @refreshContainers="getContainers()" />
              </v-tab-item>
              <v-tab-item style="height: inherit; width: 100%" value="tracking">
                <Tracking :booking="booking" :containers="booking.bookingContainers.filter(x=>x.status !='Cancelled' && x.status !='Ghost')"/>
              </v-tab-item>
              <v-tab-item style="height: inherit; width: 100%" value="stock_managed">
                <AssignManagedStock :orders="[booking]" :booking="booking"/>
              </v-tab-item>
              <v-tab-item style="height: inherit; width: 100%" value="transport">
                <Transport :booking="booking"  @bookingChange="saveBooking()" :transportLocations="transportLocations" :countries="countries" :organisations="organisations" :containerKey="containerKey" />
              </v-tab-item>
              
            </v-tabs-items>
          </div>
          <!-- <v-toolbar dense flat color="transparent" class="pb-2" >
        <v-spacer></v-spacer> -->
        <v-menu v-model="commentMenu" :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" large color="blue darken-3" style="
            position: absolute;
            bottom: 24px;
            right: 10px;
                text-transform: none;
                border-top-right-radius: 20px;
                border-top-left-radius: 20px;
              ">Comments <v-icon right small>expand_less</v-icon></v-btn>
          </template>
          <v-card width="25vw" min-width="400px" height="50vh" min-height="600px"  style="
        background-color: rgba(0, 0, 0, 0.4) !important;
        background: rgba(0, 0, 0, 0.4) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
            <v-toolbar flat dense>
              <v-toolbar-title style="font-size: 16px">Comments</v-toolbar-title> <v-spacer></v-spacer><v-btn icon color="redPop"
                @click="commentMenu = false">
              <v-icon small>close</v-icon></v-btn></v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="mx-0 px-0">
              <v-list dense>
                <v-list-item v-if="comments.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="font-size: 12px; color: grey">
                      No comments.
                    </span>
                  </v-list-item-content>
                </v-list-item>
                <v-list dense style="overflow-y: auto; height: 45vh">
                  <v-list-item v-for="item in comments" :key="item.id" class="py-0 my-1">
                    <v-list-item-avatar >
                      <v-tooltip top v-if="item.user">
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" :color="item.user.avatar?'white':'secondary'" size="36"
                            class="mt-0 pt-0">
                            <h3 v-if="!item.user.avatar">{{
    item.user.firstname.charAt(0) }}</h3>
                            <img v-else :src="item.user.avatar" referrerpolicy="no-referrer" />
                          </v-avatar>
                        </template>
                        <span style="font-size: 12px" v-if="item.user">{{ item.user.firstname }}
                          {{ item.user.surname }}</span>
                      </v-tooltip>
                    </v-list-item-avatar>

                    <v-list-item-content>
                            <v-list-item-title class="text-wrap" v-if="item.isDeleted == true" style="font-size: 11px; text-decoration: line-through;color: gray">
                              {{ item.comment }}
                            </v-list-item-title>
                            <v-list-item-title class="text-wrap" v-else style="font-size: 11px">
                                        {{ item.comment}}
                            </v-list-item-title>

                            
                            
                      <v-list-item-subtitle v-if="item.isInternal && item.isDeleted == true" style="text-decoration: line-through;color: gray" >
                        <v-chip x-small color="blue darken-3">Internal</v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <i style="font-size: 11px; color: grey">{{
    $Format.formatDate(item.createdAt).dateTimeDefault
  }}</i>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.userId == $store.state.user.id && item.isDeleted == false">
                      <v-row justify="center">

<v-tooltip top>
<template v-slot:activator="{ on: tooltip }">

<v-btn v-on="{ ...tooltip }" icon @click="openBookingDialog(item)"><v-icon small>edit</v-icon></v-btn>

</template>
<span style="font-size: 12px">Edit Comment</span>
</v-tooltip>

<v-tooltip top>
<template v-slot:activator="{ on: tooltip }">

<v-btn :loading="loadingBookingDeleteComment"   v-on="{ ...tooltip }" icon @click="deleteBookingComment(item.id)">
<v-icon color="red" small>delete</v-icon>

</v-btn>

</template>
<span style="font-size: 12px">Remove Comment</span>
</v-tooltip>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-card flat height="8vh" width="100%" style="
                    position: absolute;
                    bottom: 0;
                    background-color:transparent !important;
                    display: flex;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    vertical-align: baseline;
                    overflow-y: hidden;
                  ">
                  <v-textarea class="ml-2" no-resize hide-details outlined autofocus height="7vh"
                    style="width: 70%; contain: content" placeholder="Type a message..." v-model="comment"></v-textarea>
                  <div class="text-center">
                    <v-row class="pa-2 mx-1">
                      <v-btn small class="mt-1" color="primary" :disabled="!comment" :loading="sendingMessage" @click="sendComment()"><v-icon small
                      left>send</v-icon> Send</v-btn>
                      <v-tooltip top>
                        <template v-slot:activator="{ tooltipOn }">
                          <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="mt-1 ml-0" small :color="internalMessage ? 'blue' : 'amber'">
                            <v-icon v-on="tooltipOn" v-if="internalMessage" small>visibility_off</v-icon>
                            <v-icon v-on="tooltipOn" v-else small>share</v-icon>
                            <v-icon small right>arrow_drop_down</v-icon></v-btn>
                        </template>
                        <v-list dense subheader>
                          <v-subheader style="font-size: 14px">Visibility</v-subheader>
                          <v-divider></v-divider>
                          <v-list-item style="height: 30px" @click="internalMessage = true">
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 12px">Internal</v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">Only visible to users within your organisation</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item style="height: 30px" @click="internalMessage = false">
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 12px">Public</v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">Visible to all users with access to this booking</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                        </template>
                        <span>Set Visibility</span>
                      </v-tooltip>
                      
                    </v-row>
                  </div>
                </v-card>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
      <!-- </v-toolbar> -->
        </div>
       
      </div>
      <v-dialog v-model="onHoldWarningModal" width="400px" persistent>
      <v-card v-if="booking.customer">
        <v-card-text>
          <v-row class="mt-3 pt-5">
            <v-col cols="12" class="text-center">
              <v-icon x-large color="orange">warning </v-icon>
            </v-col>
            <v-col cols="12" class="text-center">
              <p>
                The selected customer account ({{ booking.customer.name }}) is
                currently on hold.
              </p>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-row justify="center" class="mt-2 mb-3">
                <v-btn color="success" class="mx-2" text small @click="onHoldWarningModal = false">Accept</v-btn>
                <v-btn color="red" class="mx-2" text small @click="$router.go(-1)">Reject</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="commentDialog"  width="500px">
<v-card>
  <v-toolbar flat dense style="font-size: 16px">
    <v-toolbar-title style="font-size: 16px">Edit Comment</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn :loading="savingComment" icon color="primary" @click="updateBookingComment(editedComment.id)"><v-icon small>save</v-icon></v-btn>

    <v-btn icon color="redPop" @click="commentDialog = false">
      <v-icon small>close</v-icon>
    </v-btn>
  </v-toolbar>
  <v-card-text>
    <v-textarea v-model="editedComment.comment"  outlined rows="4">
    </v-textarea>
  </v-card-text>
</v-card>
</v-dialog>
    
    </div>
    <div
      v-else
      style="
        height: 100%;
        padding-top: 0;
        margin-top: 0;
        background: transparent;
      "
      class="text-center"
    >
      <valhalla-loading />
    </div>
  </div>
</template>
<script>
import AssignManagedStock from "../components/Stock/AssignManagedStock.vue";
import Content from "../components/ManageBooking/Content.vue";
import Overview from "../components/ManageBooking/Overview.vue";
import Tracking from "../components/ManageBooking/Tracking.vue";
import Transport from "../components/ManageBooking/Transport.vue";
export default {
  components: {
    AssignManagedStock,
    Content,
    Overview,
    Tracking,
    Transport
  },
  data: () => ({
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

      { value: "FULL_PICKUP", type: "LOCAL", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "LOCAL", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "LOCAL", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "LOCAL", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "LOCAL", name: "Empty Drop Off" },

      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
    ],
    booking: {bookingContainers: []},
    bookingStatus: [
      { name: "Draft", color: "orange darken-2" },
      { name: "Pending", color: "teal" },
      { name: "Requested", color: "blue" },
      { name: "Confirmed", color: "success" },
      { name: "Rejected", color: "red" },
      { name: "Cancelled", color: "red" },
      { name: "Futile", color: "red" },
    ],
    bookingTypes: [
      // { value: "FREIGHT", icon: "mode_of_travel", color: "blue" },
      { value: "LOGISTICS", icon: "mode_of_travel", color: "blue" },
      { value: "DOCUMENTATION", icon: "article", color: "amber" },
      { value: "HAULAGE", icon: "local_shipping", color: "blue-grey" },
      { value: "MONITORING", icon: "thermostat", color: "teal" },
    ],
    commentMenu: false,
    comment: null,
    comments: [],
    commentDialog: false,
    containerKey: 200,
    containerTypes: [],
    countries: [],
    currentTab: "overview",
    editedComment: {},
    internalMessage: true,
    linkedFiles: [],
    loading: false,
    loadingBookingDeleteComment: false,
    loadingOrganisations: false,
    onHoldWarningModal: false,
    organisations: [],
    productServices: [],
    routeKey: 100,
    savingBooking: false,
    sendingMessage: false,
    savingComment: false,
    tabs: [
      { name: "Overview", value: "overview" },
      { name: "Transport", value: "transport" },
      { name: "Content", value: "content" },
      { name: "Stock Allocation", value: "stock_managed" },
      { name: "Tracking", value: "tracking" },
    ],
    transportLocations: [],
    transportLocationTypes: [],
  }),
  watch: {
    "$route.params.systemReference": {
      immediate: true,
      handler(value) {
        if (value) {
          this.getBooking(value);
        }
      },
    },
    "booking.sailingScheduleId": {
      immediate: true,
      async handler(val) {
        this.routeKey++;
      },
    },
  },
  created(){
    this.load()
  },
  computed: {
    fullRoute() {
      let result = [];
      let setTypes = [
        { type: "Port of Load", locode: this.booking.portOfLoadValue },
        {
          type: "Port of Discharge",
          locode: this.booking.portOfDischargeValue,
        },
        {
          type: "Final Destination",
          locode: this.booking.finalDestinationValue,
        },
      ];

      // POL
      result.push(setTypes[0]);

      // Transhipments
      if (
        this.booking.sailingSchedule &&
        this.booking.sailingSchedule.sailingScheduleLegs.length > 1
      ) {
        result.push({
          type: "TS Port 1",
          locode:
            this.booking.sailingSchedule.sailingScheduleLegs[0]
              .portOfDischargeValue,
        });
      }

      // POD & FD
      if (
        this.booking.portOfDischargeValue === this.booking.finalDestinationValue
      ) {
        result.push({
          type: "POD & Final Destination",
          locode: this.booking.finalDestinationValue,
        });
      } else {
        result.push(setTypes[1]);
        result.push(setTypes[2]);
      }
      return result;
    },
  },
  methods: {
    bookingTypeColor(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.color : null;
    },
    bookingTypeIcon(type) {
      let find = this.bookingTypes.find((x) => x.value == type);
      return find ? find.icon : null;
    },
    bookingUpdate(data){
      this.booking = {...this.booking, ...data}
    },
    countContainers() {
      return this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null) &&
          x.bookingId == this.booking.id
      ).length;
    },
    async deleteBookingComment(id){
    this.loadingBookingDeleteComment = true;
     let result =  await this.$API.deleteBookingComment({
          id: id,
          isDeleted: true,
          isActive: false,
          bookingId: this.booking.id
        });
        if(result){
          this.loadingBookingDeleteComment = false;
          this.getComments(this.booking.id);
        }
    },
    async load(id) {
        this.countries = await this.$API.searchCountriesBasic({});
    this.containerTypes = await this.$API.listContainerTypes();
        this.getRelations();
        this.getProductServices()
    },
    async getBooking(systemReference) {
      this.loading = true;
      this.booking = await this.$API.getBookingDetail(systemReference);
      this.booking.bookingContainers = [];
      this.transportLocationTypes = this.availableMilestones.filter(
            (x) => x.type == this.booking.movementType
          );
        if (this.booking.clientStatus == "ON HOLD") {
          this.onHoldWarningModal = true;
        }
        if (this.booking.consigneeProfileId) {
          this.booking.consigneeProfile = await this.$API.getConsigneeProfile(
            this.booking.consigneeProfileId
          );
        }
      this.getContainers();
        this.getComments();
      //   // setInterval(() => { this.setSiDueDate() }, 1000);
        setTimeout(() => {
          this.$socket.send(
            JSON.stringify({
              action: "join",
              room: "booking_" + this.booking.id,
              user: {
                id: this.$store.state.user.id,
                name:
                  this.$store.state.user.firstname +
                  " " +
                  this.$store.state.user.surname,
                avatar: this.$store.state.user.avatar,
                company: this.$store.state.currentOrg.name,
              },
            })
          );
        }, 1000);
        this.$socket.onopen = () => {
          this.$socket.send(
            JSON.stringify({
              action: "join",
              room: "booking_" + this.booking.id,
              user: {
                id: this.$store.state.user.id,
                name:
                  this.$store.state.user.firstname +
                  " " +
                  this.$store.state.user.surname,
                avatar: this.$store.state.user.avatar,
                company: this.$store.state.currentOrg.name,
              },
            })
          );
        };
      this.loading = false;
    },
    async getProductServices() {
      this.productServices = await this.$API.getProductServices();
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async getComments() {
      this.comments = await this.$API.getBookingComment(this.booking.id);
    },
    async getContainers() {
      this.booking.bookingContainers = [];
      let containers = await this.$API.getBookingContainers(this.booking.id);
      this.booking.bookingContainers = containers;
      let initialHazard = this.booking.isHazardous;
      if (!initialHazard) {
        this.booking.isHazardous = this.booking.bookingContainers.some((x) =>
          x.containerProducts.some((y) => y.hazardous == true)
        );
      }
      let uniqueFiles = [
        ...new Set(
          this.booking.bookingContainers
            .filter(
              (x) =>
                !x.isDeleted &&
                x.bookingId == this.booking.id &&
                x.status != "Cancelled" &&
                x.status != "Ghost"
            )
            .map((x) => x.shipmentFileId)
        ),
      ].filter(Boolean);
      let result = uniqueFiles.map(
        (x) =>
          this.booking.bookingContainers.find((y) => y.shipmentFileId == x)
            .shipmentFile
      );
      this.linkedFiles = result;
      this.containerKey ++
      this.setTransportLocations();
    },
    getMainStatusColor(status) {
      if (status) {
        let find = this.bookingStatus.find(
          (s) => s.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.color : "grey";
      } else return "grey";
    },
    async getRelations() {
      this.loadingOrganisations = true;
      let organisations = await this.$API.getRelationBasic({
        // params: this.params,
      });
      this.organisations = organisations.data
      this.loadingOrganisations = false;
    },
    openBookingDialog(comment) {
      // this.loadingBookingEditComment = false;
      this.editedComment = comment
      this.commentDialog = true;
    },
    productList() {
      let containers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null) &&
          x.bookingId == this.booking.id
      );
      let products = [].concat.apply(
        [],
        containers.map((x) => x.containerProducts)
      );
      let uniqueProducts = [
        ...new Set(
          products.map((x) => this.$Format.capitalizeFirstLetter(x.product.name))
        ),
      ];
      uniqueProducts.sort((a, b) => a - b);
      return uniqueProducts.join(", ");
    },
    async saveBooking() {
      try {
        let changeRegime = false;
        this.savingBooking = true;
        if (this.booking.integrationReference) {
          this.booking.integrationChange = true;
        }
        this.booking.numContainers = this.booking.bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" ||
              x.status == null ||
              x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        ).length;
        if (this.booking.integrationReference) {
          this.booking.integrationChange = true;
        }
        if (this.booking.changeRegime) {
          changeRegime = true;
          delete this.booking.changeRegime;
        }
        await this.$API.updateBooking(this.booking);

        if (changeRegime) {
          for (let i = 0; i < this.booking.bookingContainers.length; i++) {
            this.booking.bookingContainers[i].setPointTemp =
              this.booking.setPointTemp;
          }
        }
        // this.booking.id = result.id;
        this.savingBooking = false;
        this.$message.success("Successfully updated booking!");
      } catch (e) {
        this.savingBooking = false;
        this.$message.error(e.message);
      }
    },
    setNewSubscribers(subscribers){
      let bookingSubscribers = [...this.booking.subscribers, ...subscribers];
      bookingSubscribers.sort((a, b) => a.user.firstname.localeCompare(b.user.firstname));
      this.booking.subscribers = bookingSubscribers
    },
    removeSubscriber(id){
      this.booking.subscribers = this.booking.subscribers.filter(x => x.id != id)
    },
    async sendComment() {
      this.sendingMessage = true;
      let result = await this.$API.createBookingComment({
        bookingId: this.booking.id,
        comment: this.comment,
        isInternal: this.internalMessage,
      });
      if (result) {
        result.user = this.$store.state.user;
        result.isInternal = this.internalMessage;
        this.comments.push(result);
        this.comment = null;
      }
      this.sendingMessage = false;
    },
    setTransportLocations() {
      let availableTypes = this.transportLocationTypes;
      let result = [];
      for (let i = 0; i < availableTypes.length; i++) {
        let allLocations = this.booking.bookingContainers ?? [];
        allLocations = [].concat.apply(
          [],
          allLocations.map((x) =>
            x.containerMilestones.filter(
              (y) => y.type == availableTypes[i].value
            )
          )
        );
        let uniqueLocations = [
          ...new Set(allLocations.map((x) => x.description)),
        ];
        for (let j = 0; j < uniqueLocations.length; j++) {
          let locationDetails = allLocations.filter(
            (x) => x.description == uniqueLocations[j]
          );
          let obj = {
            name: locationDetails[0].description,
            lat: locationDetails[0].lat,
            lng: locationDetails[0].lng,
            poi: locationDetails[0].poi,
            description: availableTypes[i].name,
            type: availableTypes[i].value,
            count: locationDetails.length,
          };
          result.push(obj);
        }
      }
      this.transportLocations = result;
      return result;
    },
    async updateManualStatus() {
      await this.$API.updateManaulUpdateStatus({
        bookingId: this.booking.id,
        status: this.booking.state,
      });
    },
   
    async updateBookingComment(id){
      this.loadingBookingEditComment = true;
     let result =  await this.$API.updateBookingComment({
          id: id,
          isDeleted: true,
          isActive: false,
          bookingId: this.booking.id,
          userId : this.editedComment.userId,
          organisationId:this.booking.organisationId,
          comment:this.editedComment.comment
        });
        if(result){
          this.containerComments = result;
          this.commentDialog = false;
          this.getComments(this.booking.id);
          this.loadingBookingEditComment = false;
          
        }
    },
  },
};
</script>
<style scoped>
* {
  --gutters: 0.1rem;
  --toolBarHeight: 36px;
  --tabHeight: 30px;
  --darkLayer: var(--v-greyDarker-base) !important;
}

.topBar {
  height: var(--toolBarHeight);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--v-greyDarker-base) !important;
  background: var(--v-greyDarker-base) !important;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight));
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  max-height: calc(100vh - var(--toolBarHeight) - var(--tabHeight));
  height: 100%;
  width: 100%;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyDarker-base) !important;
  border-radius: 0px;
}
.glass {
  background-color: rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}
</style>